import React from 'react';
import styled from '@emotion/styled';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Layout, Container } from 'layouts';

const PostList = styled.ul`
    margin-top: 0;
    list-style-type: none;
    a {
        color: ${props => props.theme.colors.link};
        text-decoration: none;
        &:hover {
            color: ${props => props.theme.colors.linkHover};
            text-decoration: underline;
        }
    }
`;


const Archived = ({ data, pageContext }) => {
    const posts = data.allMarkdownRemark.edges;
    const { slug } = pageContext;

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ];

    const slugs = slug.split("/").filter(el => el.trim() !== '');
    let infoText = 'All posts for the ';
    let titleText = 'Archives for ';
    if (slugs.length > 1) {
        infoText += 'month ' + months[parseInt(slugs[1])-1] + ', ' + slugs[0];
        titleText += months[parseInt(slugs[1])-1] + ', ' + slugs[0];
    }else {
        infoText += 'year ' + slugs[0];
        titleText += slugs[0];
    }

  return (
    <Layout title={titleText}>
        <Container title={'Archives'} info={infoText}>
            <PostList>
                {
                    posts.map(({ node }) => {
                        const { title, date } = node.frontmatter;
                        const { slug } = node.fields;

                        return(
                            <li>
                            <span key={slug}>
                                <Link to={slug}>
                                    {title}
                                </Link>
                                <small><span> | {date}</span></small>
                            </span>
                            </li>
                        );
                    })
                }
            </PostList>
        </Container>
    </Layout>
  )
}

export default Archived;

Archived.propTypes = {
    pageContext: PropTypes.shape({
      slug: PropTypes.string,
      slugRegex: PropTypes.string,
    }).isRequired,
    data: PropTypes.object.isRequired,
  };

  export const query = graphql`
      query PostsBySlugPart($slugRegex: String!) {
        allMarkdownRemark(
            sort: {fields: [frontmatter___date], order: DESC}, 
            filter: {fileAbsolutePath: {regex: "/posts/"}, fields: {slug: {regex: $slugRegex}}}
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    id
                    excerpt(pruneLength:150)
                    frontmatter {
                        title
                        date(formatString: "MMMM DD, YYYY")
                    }
                }
            }
        }
    }
  `;